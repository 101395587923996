import React from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import JobSeekerForm from './component/JobSeekerForm';
import RegistrationSuccess from './component/registerationsuccess';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<JobSeekerForm />} />
        <Route path="/success" element={<RegistrationSuccess />} />
      </Routes>
    </Router>
  );
}

export default App;