import React from "react";
const RegistrationSuccess = () => {
  return (
    <div
      className="success"
      style={{
        border: "2px solid green",
        padding: "10px",
        margin: "20px auto",
        textAlign: "center",
        width: "60vw",
      }}
    >
      <h2 style={{ fontSize: "24px", marginBottom: "10px" }}>
        Registration Successful!
      </h2>
      <p>Thank you for registering as a job seeker.</p>
    </div>
  );
};

export default RegistrationSuccess;
