import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px auto;
  max-width: 600px;
`;

const InputLabel = styled.label`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: red;
  margin-top: 10px;
`;
const JobSeekerForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [resume, setResume] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('fullName', fullName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('resume', resume);

    try {
      const response = await axios.post('http://localhost:8000/job-seekers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
      navigate('/success')
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const handleResumeUpload = (event) => {
    setResume(event.target.files[0]);
  };

  return (
    <FormContainer>
      {error && <ErrorText>{error}</ErrorText>}
      <form onSubmit={handleSubmit}>
        <InputLabel htmlFor="fullName">Full Name:</InputLabel>
        <InputField
          type="text"
          id="fullName"
          value={fullName}
          onChange={(event) => setFullName(event.target.value)}
        />

        <InputLabel htmlFor="email">Email:</InputLabel>
        <InputField
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <InputLabel htmlFor="phone">Phone:</InputLabel>
        <InputField
          type="tel"
          id="phone"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
        />

        <InputLabel htmlFor="password">Password:</InputLabel>
        <InputField
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <InputLabel htmlFor="resume">Resume:</InputLabel>
        <InputField
          type="file"
          id="resume"
          onChange={handleResumeUpload}
        />

        <SubmitButton type="submit">Submit</SubmitButton>
      </form>
    </FormContainer>
  );
};

export default JobSeekerForm;
